<template>
  <div class="rarity">
    <h1><span class="spinner" />&nbsp;&nbsp;HOLLOW RARITY TOOL&nbsp;&nbsp;<span class="spinner" /></h1>
    <input
      class="number-input"
      type="text"
      v-model="number"
      placeholder="3110"
    >
    <h5><br>This only returns detais about <i>minted</i> Hollows by prompting<br>you to login with your Tezos wallet for each search.<br><br>This keeps things fair!</h5>
    <button
      class="lookup-button"
      :class="`${bigButtonPressed ? 'pressed' : ''}`"
      @click="getRarity"
    >
      <span>GET<br>RANK</span>
    </button>
    <h2 v-if="error">
      {{ error }}
    </h2>
    <h2 v-if="loading">
      Loading...
    </h2>
    <div
      class="response"
      v-if="foundPunk"
    >
      <div class="background-color">
        <h2>Here's your HOLLOW:</h2>
        <h3>🎃&nbsp;&nbsp;Hollow&nbsp;&nbsp;🎃</h3>
        <div class="main-attr punk-name">
          #{{ this.submittedNumber }}<br>
          <a
            :href="`https://objkt.com/asset/hollows/${this.number}`"
            target="_blank"
          >Objkt.com Link</a>
        </div>
        <h3>🏆 Rank 🏆</h3>
        <div class="main-attr punk-rank">
          {{ this.foundPunkRank }} / 3110 HOLLOWS
        </div>
        <h3>👾 Score 👾</h3>
        <div class="main-attr punk-score">
          {{ this.foundPunkScore }} / 40430 POINTS
        </div>
        <h3>🖼 Image 🖼</h3>
        <a
          class="image-link"
          :href="foundPunkImage"
          target="_blank"
        >
          <label>Click here to see image in new tab!
            <span><br>IPFS is slow so <i>plz be patient!</i><br>Image will appear below when loaded as well <i>eventually</i>...</span>
          </label>
          <img
            class="hollow-image"
            :src="foundPunkImage"
          >
        </a>
        <span class="breakdown">TRAITS:</span>
        <div
          class="attributes"
          v-for="(key, index) in foundPunkAttributeKeys"
          :key="key"
        >
          <h4>🔥&nbsp;&nbsp;{{ key.split('-')[0] }}&nbsp;&nbsp;🔥</h4>
          <h5 style="display: inline;">
            {{ key.split('-')[1] }}
          </h5>
          <span class="attribute-score">
            {{ foundPunkAttributeValues[index].toFixed(2) }} / 3110 POINTS
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BeaconWallet } from '@taquito/beacon-wallet'
import { TezosToolkit } from '@taquito/taquito'
import RANKINGS from '@/assets/rankings.json'
import { PermissionScope } from '@airgap/beacon-sdk'

export default {
  name: 'Rarity',
  data () {
    return {
      number: '',
      submittedNumber: '',
      response: '',
      foundPunk: undefined,
      foundPunkImage: '',
      foundPunkURL: '',
      foundPunkRank: '',
      foundPunkScore: '',
      foundPunkAttributeKeys: [],
      foundPunkAttributeValues: [],
      error: '',
      bigButtonPressed: false,
      loading: false
    }
  },
  computed: {
    isDev () {
      const searchParams = new URLSearchParams(window.location.search)
      return searchParams.get('dev') || process.env.NODE_ENV === 'development'
    }
  },
  created () {
    if (this.$route.params.number) {
      this.getRarityImmediate(this.$route.params.number)
    }
  },
  methods: {
    // Handles logging in via signed transaction
    async getRarity () {
      try {
        this.bigButtonPressed = true
        this.error = ''

        const wallet = new BeaconWallet({
          name: 'HOLLOWS'
        })
        // const net = 'mainnet'
        const rpc = 'https://mainnet.smartpy.io'
        const contractName = 'KT1Ak8AFA54waFVMyPXNE925cUaQjFEqxuYN'
        await wallet.requestPermissions({
          scopes: [PermissionScope.OPERATION_REQUEST]
        })
        this.loading = true
        const Tezos = new TezosToolkit(rpc)
        await Tezos.setWalletProvider(wallet)
        const hollows = await Tezos.wallet.at(contractName)
        const stored = await hollows.storage()
        const defs = stored.token_defs

        let exists = false
        defs.forEach((def) => {
          if (parseInt(this.number) === def.from_.c[0]) {
            exists = true
          }
        })

        if (!exists) {
          this.error = 'HOLLOW Not minted yet!'
          this.foundPunkImage = ''
          this.foundPunkURL = ''
          this.foundPunkScore = null
          this.foundPunkRank = null
          this.foundPunk = null
          this.foundPunkAttributeValues = []
          this.foundPunkAttributeKeys = []
          this.loading = false
          window.setTimeout(() => { this.bigButtonPressed = false }, 500)
          return
        }

        RANKINGS.forEach((item, index) => {
          if (item.number === parseInt(this.number)) {
            this.foundPunkRank = 3110 - index
            this.foundPunk = item
          }
        })

        if (!this.foundPunkRank || !this.foundPunk) {
          this.error = 'HOLLOW Does Not Exist!'
          this.error = 'HOLLOW Not minted yet!'
          this.foundPunkImage = ''
          this.foundPunkURL = ''
          this.foundPunkScore = null
          this.foundPunkRank = null
          this.foundPunk = null
          this.foundPunkAttributeValues = []
          this.foundPunkAttributeKeys = []
          this.loading = false
          window.setTimeout(() => { this.bigButtonPressed = false }, 500)
          return
        }

        this.submittedNumber = this.number

        let theJSON = await fetch(`/json/${this.number}.json`)
        theJSON = await theJSON.json()

        // need to index into JSON for this, the format has onto ipfs
        this.foundPunkImage = `https://cloudflare-ipfs.com/ipfs/${theJSON.formats[0].hash}`
        this.foundPunkURL = '#'
        this.foundPunkScore = this.foundPunk.rarityScore.toFixed(2)

        this.foundPunkAttributeValues = Object.values(this.foundPunk.attributeRarities)
        this.foundPunkAttributeKeys = Object.keys(this.foundPunk.attributeRarities)
        window.setTimeout(() => { this.bigButtonPressed = false }, 500)
      } catch {
        this.loading = false
      }
      this.loading = false
    },
    async getRarityImmediate (num) {
      this.bigButtonPressed = true
      this.number = num
      this.error = ''
      this.loading = true

      const wallet = new BeaconWallet({
        name: 'HOLLOWS'
      })
      const net = 'mainnet'
      const rpc = 'https://mainnet.smartpy.io'
      const contractName = 'KT1Ak8AFA54waFVMyPXNE925cUaQjFEqxuYN'
      await wallet.requestPermissions({
        network: { type: net }
      })
      const Tezos = new TezosToolkit(rpc)
      await Tezos.setWalletProvider(wallet)
      const hollows = await Tezos.wallet.at(contractName)
      const stored = await hollows.storage()
      const defs = stored.token_defs

      let exists = false

      defs.forEach((def) => {
        console.log(def.from_.c[0])
        if (parseInt(this.number) === def.from_.c[0]) {
          exists = true
        }
      })

      if (!exists) {
        this.error = 'HOLLOW Not minted yet!'
        this.foundPunkImage = ''
        this.foundPunkURL = ''
        this.foundPunkScore = null
        this.foundPunkRank = null
        this.foundPunk = null
        this.foundPunkAttributeValues = []
        this.foundPunkAttributeKeys = []
        this.loading = false
        window.setTimeout(() => { this.bigButtonPressed = false }, 500)
        return
      }

      RANKINGS.forEach((item, index) => {
        if (item.number === parseInt(num)) {
          this.foundPunkRank = 3110 - index
          this.foundPunk = item
        }
      })

      if (!this.foundPunkRank || !this.foundPunk) {
        this.error = 'HOLLOW Does Not Exist!'
        this.foundPunkImage = ''
        this.foundPunkURL = ''
        this.foundPunkScore = null
        this.foundPunkRank = null
        this.foundPunk = null
        this.foundPunkAttributeValues = []
        this.foundPunkAttributeKeys = []
        this.loading = false
        window.setTimeout(() => { this.bigButtonPressed = false }, 500)
        return
      }

      this.submittedNumber = this.number

      let theJSON = await fetch(`/json/${num}.json`)
      theJSON = await theJSON.json()

      // need to index into JSON for this, the format has onto ipfs
      this.foundPunkImage = `https://ipfs.io/ipfs/${theJSON.formats[0].hash}`
      this.foundPunkURL = '#'
      this.foundPunkScore = this.foundPunk.rarityScore.toFixed(2)

      this.foundPunkAttributeValues = Object.values(this.foundPunk.attributeRarities)
      this.foundPunkAttributeKeys = Object.keys(this.foundPunk.attributeRarities)
      window.setTimeout(() => { this.bigButtonPressed = false }, 500)
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  image-rendering: pixelated;
  image-rendering: pixelated;
  position: absolute;
  transform: scale(1);
  top: 10px;
  left: 0;
  right: 0;
  background: #010001;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

input {
  display: block;
  margin: 0 auto;
}

.lookup-button {
  width: 99px;
  height: 55px;
  margin: auto;
  border: none;
  outline: none;
  top: 0;
  left: 6px;
  right: 0;
  bottom: 244px;
  font-family: 'Small Pixel';
  color: red;
  margin: 20px;

  span {
    position: relative;
    left: 2px;
    color: orange;
    letter-spacing: 1px;
    font-size: 18px;
  }

  &:hover {
    border: 2px solid orange;
    cursor: pointer;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.7;
  }
}

.background-color {
  background: #0c0c0c;
  padding: 10px 40px;
  border: 2px dashed orange;
}

.rarity {
  background: black;
}

.response {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-size: contain;
  color: white;
  font-family: 'Small Pixel';
  padding-bottom: 100px;
}

.punk-image {
  margin: 10px;
  border: 2px solid orange;
}

input {
  border:none;
  background-image:none;
  background-color:transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

input {
  text-align: center;
  font-family:  'Small Pixel';
  width: 100px;
  font-size: 30px;
  background: orange;
  border: black solid 2px;
  color: white;
}

h1 {
  font-family:  'Small Pixel';
  color: orange;
  font-size: 30px;

  @media screen and (max-width: 800px) {
    font-size: 22px;
  }
}

h2 {
  font-family: 'Small Pixel';
}

h3 {
  margin: 0 0 5px 0;
}

.main-attr {
  color: #fff900;
  margin: 0;
  margin: 0 0 20px 0;

  a {
    color: inherit;
    font-size: 12px;
  }
}

h4 {
 margin: 0 0 5px 0;
 color: orange;
}

h5 {
  color: #fff900;
  margin: 0;
}

.breakdown {
  display: block;
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.attributes {
  margin-bottom: 20px;
}

.attribute-score {
  margin-left: 10px;
  font-size: 14px;
  color: #fff900;
}

.hollow-image {
  max-width: 400px;
  width: 100%;
  margin: 20px 0;
  display: block;
}

.image-link {
  color: orange;
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  span {
    display: block;
    font-size: 10px;
    text-decoration: none;
  }
}

.spinner {
  display: inline-block;
  height: 30px;
  width: 30px;
  vertical-align: middle;
}
</style>
