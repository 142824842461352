import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Rarity from '../views/Rarity.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/lookup',
    name: 'Rarity',
    component: Rarity
  },
  {
    path: '/lookup/:number',
    name: 'RarityHardlink',
    component: Rarity
  },
  {
    path: '/contract',
    beforeEnter (to, from, next) {
      window.location.href = 'https://better-call.dev/mainnet/KT1Ak8AFA54waFVMyPXNE925cUaQjFEqxuYN/metadata'
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
