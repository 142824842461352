<template>
  <div class="home">
    <video
      autoplay
      muted
      loop
      id="myVideo"
    >
      <source
        src="~@/assets/BG.mp4"
        type="video/mp4"
      >
    </video>

    <button
      @click="buy"
      class="mint"
    >
      <div class="sign-glow" />
      <label>10ꜩ/HOLLOW<br>MINT OPEN</label>
    </button>
    <button
      @click="openLookup"
      class="lookup"
    >
      <div class="sign-lookup" />
    </button>
    <div class="links">
      <a
        href="https://discord.gg/Gr27CC4mxs"
        target="_blank"
      >
        <img
          class="discord"
          src="~@/assets/discord.png"
        >
      </a>
      <a
        class="twitter-link"
        href="https://twitter.com/hollowsnft"
        target="_blank"
      >
        🎃
      </a>
      <a
        class="objkt-link"
        href="https://objkt.com/collection/hollows"
        target="_blank"
      >
        👻
      </a>
      <a
        class="contract-link"
        href="https://better-call.dev/mainnet/KT1Ak8AFA54waFVMyPXNE925cUaQjFEqxuYN/tokens"
        target="_blank"
      >
        📜
      </a>
    </div>
    <div class="gallery">
      <div class="gallery-images-wrapper">
        <a
          href="https://objkt.com/asset/hollows/3090"
          target="_blank"
        >
          <img
            class="gallery-image"
            src="@/assets/gallery/19.jpeg"
          >
        </a>
        <a
          href="https://objkt.com/asset/hollows/3099"
          target="_blank"
        >
          <img
            class="gallery-image"
            src="@/assets/gallery/20.jpeg"
          >
        </a>
        <a
          href="https://objkt.com/asset/hollows/3093"
          target="_blank"
        >
          <img
            class="gallery-image"
            src="@/assets/gallery/14.jpeg"
          >
        </a>
        <a
          href="https://objkt.com/asset/hollows/3094"
          target="_blank"
        >
          <img
            class="gallery-image"
            src="@/assets/gallery/15.jpeg"
          >
        </a>
        <a
          href="https://objkt.com/asset/hollows/1"
          target="_blank"
        >
          <img
            class="gallery-image"
            src="@/assets/gallery/1.jpeg"
          >
        </a>
        <a
          href="https://objkt.com/asset/hollows/5"
          target="_blank"
        >
          <img
            class="gallery-image"
            src="@/assets/gallery/5.jpeg"
          >
        </a>
        <a
          href="https://objkt.com/asset/hollows/6"
          target="_blank"
        >
          <img
            class="gallery-image"
            src="@/assets/gallery/6.jpeg"
          >
        </a>
        <a
          href="https://objkt.com/asset/hollows/7"
          target="_blank"
        >
          <img
            class="gallery-image"
            src="@/assets/gallery/7.jpeg"
          >
        </a>
        <a
          href="https://objkt.com/asset/hollows/8"
          target="_blank"
        >
          <img
            class="gallery-image"
            src="@/assets/gallery/8.jpeg"
          >
        </a>
        <a
          href="https://objkt.com/asset/hollows/9"
          target="_blank"
        >
          <img
            class="gallery-image"
            src="@/assets/gallery/9.jpeg"
          >
        </a>
        <a
          href="https://objkt.com/asset/hollows/10"
          target="_blank"
        >
          <img
            class="gallery-image"
            src="@/assets/gallery/10.jpeg"
          >
        </a>
        <a
          href="https://objkt.com/asset/hollows/3090"
          target="_blank"
        >
          <img
            class="gallery-image"
            src="@/assets/gallery/11.jpeg"
          >
        </a>
        <a
          href="https://objkt.com/asset/hollows/3091"
          target="_blank"
        >
          <img
            class="gallery-image"
            src="@/assets/gallery/12.jpeg"
          >
        </a>
        <a
          href="https://objkt.com/asset/hollows/3092"
          target="_blank"
        >
          <img
            class="gallery-image"
            src="@/assets/gallery/13.jpeg"
          >
        </a>
        <a
          href="https://objkt.com/asset/hollows/3095"
          target="_blank"
        >
          <img
            class="gallery-image"
            src="@/assets/gallery/16.jpeg"
          >
        </a>
      </div>
      <div class="footer">
        <span>art by pixantle</span><br>
        <span>tech by white lights</span>
      </div>
    </div>
    <div
      class="status-modal"
      v-if="minting || link"
    >
      <span
        v-if="!link"
        class="loading"
      >
        Summoning your HOLLOW!<br>
        <div
          class="lds-ellipsis"
        >
          <div /><div /><div /><div />
        </div>
      </span>
      <div
        v-if="link"
        class="minted"
        target="_blank"
      >
        🎉<br>
        <a
          target="_blank"
          :href="objktLink"
        >You minted HOLLOW #{{ hollowNumber }}!</a>
        <span v-if="objktLink">
          <a
            :href="objktLink"
            target="_blank"
          >
            View on objkt.com
          </a>
          <br>Reload page to mint again!
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { BeaconWallet } from '@taquito/beacon-wallet'
import { TezosToolkit } from '@taquito/taquito'

const PRICE = 10

export default {
  name: 'Home',
  props: {
    msg: String
  },
  data () {
    return {
      // core vars
      loggedIn: false,
      accountAddress: '',
      signature: '',
      provider: undefined,
      contract: undefined,
      activated: false,
      purchaseCount: 1,
      browserChain: undefined,
      // ux vars
      minting: false,
      minted: false,
      punksLeft: 'XXXX',
      bigButtonPressed: false,
      connectButtonPressed: false,
      showExplosion: false,
      showLore: false,
      link: '',
      objktLink: '',
      hollowNumber: ''
    }
  },
  computed: {
    isDev () {
      const searchParams = new URLSearchParams(window.location.search)
      return searchParams.get('dev') && process.env.NODE_ENV === 'development'
    }
  },
  methods: {
    /*
     * Connect with beacon, and buy!
     */
    async buy () {
      this.bigButtonPressed = true
      this.minting = true

      try {
        const wallet = new BeaconWallet({
          name: 'HOLLOWS'
        })
        const net = this.isDev ? 'ghostnet' : 'mainnet'
        const rpc = this.isDev ? 'https://ghostnet.api.tez.ie' : 'https://mainnet.api.tez.ie'
        const contractName = this.isDev ? 'KT1EYSCKtSvxU7U85HScHB3gH6YtF94eEEs1' : 'KT1Ak8AFA54waFVMyPXNE925cUaQjFEqxuYN'
        await wallet.requestPermissions({
          network: { type: net }
        })
        const Tezos = new TezosToolkit(rpc)
        await Tezos.setWalletProvider(wallet)
        const hollows = await Tezos.wallet.at(contractName)

        const operation = await hollows.methods.mint().send({
          amount: PRICE
        })
        await operation.confirmation()
        this.hollowNumber = operation._operationResult._events[0][0].metadata.operation_result.lazy_storage_diff[0].diff.updates[0].key.int
        this.link = this.isDev ? `https://ghostnet.tzkt.io/${operation.opHash}` : `https://tzkt.io/${operation.opHash}`
        this.objktLink = 'https://objkt.com/collection/hollows/' + this.hollowNumber
      } catch (err) {
        console.error(err)
      }

      // Update UX no matter what happens
      this.minting = false
      this.bigButtonPressed = false
    },

    openLookup () {
      window.open('https://raritycheck.xyz/ords/r/alcisbragi/rarity-check/collection?p2_coll_name=HOLLOWS&p2_coll_id=61&session=108350613303315&cs=1qMsqmdUgTgxsF9NMRnr9fV0HKCGeLM9n3wY8dWB7TZCDEIfDJTJZWs2vX3TM-ceXzF81qjvR2Otr6R9YKu42Sw', '_blank')
    }
  }
}
</script>

<style lang="scss">
  body {
    cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>🎃</text></svg>")
        16 0,
      auto;
    height: 100%;
    width: 100%;
  }

  .home {
    width: 100%;
    height: 100%;
    background: #010001;

    @media screen and (max-width: 800px) {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: flex-start;
      height: auto;
      width: 100%;
    }
  }

  .links {
    position: sticky;
    width: 50px;
    top: 32px;
    left: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    padding: 20px 30px;
    border: 2px solid orange;
    border-radius: 6px;
    gap: 10px;

    @media screen and (max-width: 800px) {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 200px;
      margin-top: 50px;
      top: inherit;
      left: inherit;
      justify-content: space-evenly;
      align-content: center;
      align-items: center;
      padding: 20px 30px;
      border: 2px dashed orange;
      border-radius: 5px;
    }

    a {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 0.2s ease;
      border: 2px solid transparent;
      font-size: 24px;
      text-decoration: none;

      &:hover {
        border: 2px solid orange;
      }
    }

    img {
      height: 36px;
      width: 36px;
      transition: opacity 0.2s ease;
      border: 2px solid transparent;
      text-decoration: none;
    }
  }

  .mint {
    height: 300px;
    width: 150px;
    background: url("~@/assets/mintsign.png") no-repeat center center;
    background-size: contain;
    z-index: 10000000;
    position: absolute;
    border: none;
    outline: none;
    margin: auto;
    top: 0;
    left: 0;
    right: 380px;
    bottom: 0;

    @media screen and (max-width: 800px) {
      font-size: 22px;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: relative;
      display: block;
      margin-top: 20px;
    }

    label {
      color: red;
      z-index: 1000000000;
      position: absolute;
      bottom: -4em;
      font-size: 14px;
      left: 0;
      right: 0;
      margin: auto;
      font-family: 'Small Pixel';
    }

    .sign-glow {
      display: none;
      position: relative;
      background: url("~@/assets/mintglow.png") no-repeat center center;
      background-size: contain;
      height: 100%;
      width: 101%;
      bottom: 23px;
    }

    &:hover {
      cursor: pointer;

      .sign-glow {
        display: block;
      }
    }
  }

  .lookup {
    height: 100px;
    width: 150px;
    background: url("~@/assets/lookup.gif") no-repeat center center;
    background-size: contain;
    z-index: 10000000;
    position: absolute;
    border: none;
    outline: none;
    margin: auto;
    top: 0;
    left: 450px;
    right: 0;
    bottom: 0;

    @media screen and (max-width: 800px) {
      position: relative;
      display: block;
      margin: auto;
      left: 0;
      margin-top: 80px;
    }

    .sign-lookup {
      display: none;
      position: relative;
      background: url("~@/assets/lookupglow.gif") no-repeat center center;
      background-size: contain;
      height: 110%;
      width: 110%;
      bottom: 0;
    }

    &:hover {
      cursor: pointer;

      .sign-lookup {
        display: block;
        height: 118%;
        left: -7px;
        bottom: 11px;
      }
    }
  }

  .status-modal {
    position: fixed;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: orange;
    font-family: monospace;
    font-size: 40px;
    margin: auto;
    top: 25%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 100000000;
  }

  .loading {
    background: black;
    padding: 70px;
    border: 1px solid #CCC;
    border-radius: 10px;

    & > * {
      font-size: 16px;
      color: #CCC;
    }
  }

  .minted {
    background: black;
    padding: 70px;
    border: 1px dashed orange;
    color: orange;

    a {
      color: orange;
      transform: color 0.2s ease;

      &:hover {
        color: red;
      }
    }

    span {
      display: block;
      text-decoration: none;
      font-size: 16px;
      margin: 20px;
    }
  }

  #myVideo {
    position: fixed;
    right: 0;
    top: 0;
    min-width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 815px;

    @media screen and (max-width: 800px) {
      min-width: 100%;
      min-height: auto;
      max-width: 100%;
      max-height: auto;
      position: relative;
      display: block;
    }
  }

  .lds-ellipsis {
    display: block;
    position: relative;
    width: 80px;
    height: 20px;
    margin: 20px auto;
  }

  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: orange;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  .gallery {
    position: fixed;
    top: 940px;
    max-width: 100%;
    padding: 64px;

    .gallery-images-wrapper {
      display: grid;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      column-gap: 50px;
      width: 100%;

      .gallery-image {
        border: 2px solid transparent;
        margin: 10px 0;
        width: 100%;
        border-radius: 6px;

        &:hover {
          border: 2px dashed orange;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .mint-gallery {
      font-family: monospace;
      color: orange;
      font-size: 30px;
      font-weight: bold;
      text-decoration: underline;
      display: block;
      position: relative;
      top: 0;
      margin-top: 75px;
    }

    .gallery {
      display: block;
      position: relative;
      width: 100%;
      top: 0;
      padding: 25px;
      box-sizing: border-box;

      .gallery-images-wrapper {
        display: grid;
        position: relative;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        column-gap: 0;

        .gallery-image {
          max-width: 75%;
          margin-top: 20px;
        }
      }
    }
  }

  .footer {
    font-family: monospace;
    text-transform: uppercase;
    font-size: 14px;
    color: white;
    padding: 32px 0 0 0;
  }

</style>
